import "lodash";
import "../scss/main.scss";
import $ from "jquery";
import "slick-carousel/slick/slick";
import "slick-carousel/slick/slick-theme.scss";
import "slick-carousel/slick/slick.scss";
import { Fancybox } from "@fancyapps/ui";

Fancybox.bind("[data-fancybox]", {});

$(document).ready(() => {
  initSliders();
  initMobileMenu();
})

window.onscroll = () => {stickyHeader()};

function stickyHeader() {
  const header = document.querySelector("header");
  const sticky = header.offsetTop;
  if (window.scrollY > sticky) {
    header.classList.add("sticky");
  } else {
    header.classList.remove("sticky");
  }
}

function initSliders() {
  initPartnersSlider();
  initMainSlider();
}

function initMainSlider() {
  const slider = document.querySelector(".main-slider .slides");
  if(slider) {
    $(slider).slick({
      autoplay: true,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      dots: true,
      speed: 500,
      fade: true,
      cssEase: 'linear',
      prevArrow: "<div class='arrow-prev'><i class='fa fa-angle-left'></i></div>",
      nextArrow: "<div class='arrow-next'><i class='fa fa-angle-right'></i></div>",
    });
  }
}

function initPartnersSlider() {
  const partnersSlider = document.querySelector(".partners-slider");
  if(partnersSlider) {
    $(partnersSlider).slick({
      autoplay: true,
      autoplaySpeed: 5000,
      infinite: false,
      slidesToShow: 8,
      slidesToScroll: 1,
      arrows: false,
      dots: true,
      prevArrow: "<div class='arrow-prev'><i class='fa fa-angle-left'></i></div>",
      nextArrow: "<div class='arrow-next'><i class='fa fa-angle-right'></i></div>",
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 1,
            arrows: false,
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            arrows: false,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: false,
          }
        },
        {
          breakpoint: 545,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            arrows: false,
          }
        }
      ]
    });
  }
}

function initMobileMenu() {
  const mobileMenu = document.querySelector(".mobile-menu");
  if(mobileMenu) {
    const list = mobileMenu.querySelectorAll("ul li");
    for(let i = 0; i < list.length; i++) {
      const toggleSubmenu = list[i].querySelector(".toggle-submenu");
      if(toggleSubmenu) {
        toggleSubmenu.onclick = () => {
          const submenu = toggleSubmenu.nextElementSibling;
          if(!submenu.classList.contains("toggled")) {
            submenu.classList.add("toggled");

            toggleSubmenu.querySelector("i").classList.remove("fa-angle-down");
            toggleSubmenu.querySelector("i").classList.add("fa-angle-up");
          } else {
            submenu.classList.remove("toggled");

            toggleSubmenu.querySelector("i").classList.add("fa-angle-down");
            toggleSubmenu.querySelector("i").classList.remove("fa-angle-up");
          }
        }
      }
    }

    const closeMobileMenu = mobileMenu.querySelector(".close-mobile-menu");
    if(closeMobileMenu) {
      closeMobileMenu.onclick = () => {
        mobileMenu.style.display = "none";
      }
    }

    const toggleMobileMenu = document.querySelector(".toggle-mobile-menu");
    if(toggleMobileMenu) {
      toggleMobileMenu.onclick = () => {
        mobileMenu.style.display = "block";
      }
    }
  }
}